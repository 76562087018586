.dCheckboxWrapper.lg input[type=checkbox] {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  padding: 10px;
}

.dCheckboxWrapper.lg label {
  font-size: 1rem;
}

.dCheckboxWrapper .form-check {
  direction: ltr;
}

.dCheckboxWrapper .form-check-inline .form-check-label {
  padding-left: 0.5rem !important;
}

.rtl .dCheckboxWrapper .form-check-inline .form-check-label {
  padding-right: 1.5rem !important;
  padding-left: unset !important;
}

.rtl .dCheckboxWrapper .form-check-inline .form-check-input {
  margin-right: 0 !important;
}

.ltr .dCheckboxWrapper .form-check-inline .form-check-input {
  margin-left: 0 !important;
}

.dCheckboxWrapper .form-check-input {
  opacity: 0;
}

.form-check-input[type="checkbox"] + label:before,
label.btn input[type="checkbox"] + label:before {
  top: -2px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  border: 2px solid #8a8a8a;
  border-radius: 1px;
}

.rtl .form-check-input[type="checkbox"] + label:before,
.rtl label.btn input[type="checkbox"] + label:before {
  right: 0;
}

.rtl .form-check-input[type="checkbox"] + label:before,
.rtl label.btn input[type="checkbox"] + label:before {
  left: 0;
}

.form-check-input[type="checkbox"]:checked + label:before,
label.btn input[type="checkbox"]:checked + label:before {
  top: -4px;
  width: 12px;
  height: 1.375rem;
  border-top: 2px solid transparent;
  border-right: 2px solid $on-prime;
  border-bottom: 2px solid $on-prime;
  border-left: 2px solid transparent;
  transform: rotate(40deg);
  transform-origin: 100% 100%;
  backface-visibility: hidden;
}

.form-check-input[type="checkbox"] + label:before,
.form-check-input[type="checkbox"] + label:after,
label.btn input[type="checkbox"] + label:before,
label.btn input[type="checkbox"] + label:after {
  left: 0;
}

.rtl .form-check-input[type='checkbox'] + label:before,
.rtl label.btn input[type='checkbox'] + label:before {
  right: 0;
}

.form-check-input[type="checkbox"]:checked + label:before,
label.btn input[type="checkbox"]:checked + label:before {
  top: 0;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid transparent;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

.rtl .form-check-input[type="checkbox"]:checked + label:before,
.rtl label.btn input[type="checkbox"]:checked + label:before {
  right: 10px;
}

.ltr .form-check-input[type="checkbox"]:checked + label:before,
.ltr label.btn input[type="checkbox"]:checked + label:before {
  left: 1px;
}

.dCheckboxWrapper.sm .form-check-input[type='checkbox']:checked + label:before {
  top: -0.2rem;
  left: 0.2px;
}

.form-check-input[type="checkbox"] + label:after,
label.btn input[type="checkbox"] + label:after {
  border-radius: .125rem;
}

.form-check-input[type="checkbox"] + label:before,
.form-check-input[type="checkbox"] + label:after,
label.btn input[type="checkbox"] + label:before,
label.btn input[type="checkbox"] + label:after {
  position: absolute;
  z-index: 1;
  content: "";
}

.rtl .form-check-input[type="checkbox"] + label:before,
.rtl .form-check-input[type="checkbox"] + label:after,
.rtl label.btn input[type="checkbox"] + label:before,
.rtl label.btn input[type="checkbox"] + label:after {
  right: 0;
}

input[type="checkbox"]:checked + label:after {
  top: 0;
  z-index: 0;
  width: 20px;
  height: 20px;
}

.dCheckboxWrapper.sm[type='checkbox'] + label:after,
.dCheckboxWrapper.sm .form-check-input[type='checkbox']:not(:checked) + label:after,
.dCheckboxWrapper.sm .form-check-input[type='checkbox']:checked + label:after {
  width: 1rem;
  height: 1rem;
  border-width: 0.05rem;
}

.dCheckboxWrapper.sm[type='checkbox']:checked + label:after,
.dCheckboxWrapper.sm .form-check-input[type='checkbox']:checked + label:after {
  border-width: 0.05rem !important;
}

.dCheckboxWrapper .form-check {
  display: flex;
  margin: 0.5rem 0;
}

.rtl .dCheckboxWrapper .form-check{
  direction: rtl;
}
.dCheckboxWrapper .form-check-inline {
  display: inline-flex;
  margin: 0.5rem 1rem 0.5rem 0;
}

.dCheckboxWrapper .form-check-label {
  margin-top: 2px;
  padding-left: 5px;
}
.rtl .dCheckboxWrapper .form-check-label {
  padding-left: unset;
  padding-right: 25px;
}
.rtl .checkboxForm {
  float: right;
}

.ltr .checkboxForm {
  float: left;
}

.dCheckboxWrapper.primary[type='checkbox']:checked + label:after,
.dCheckboxWrapper.primary .form-check-input[type='checkbox']:checked + label:after {
  background-color: $primary;
  border: 1px solid darken($primary, 10%);
}

.dCheckboxWrapper.secondary[type='checkbox']:checked + label:after,
.dCheckboxWrapper.secondary .form-check-input[type='checkbox']:checked + label:after {
  background-color: $secondary;
  border: 1px solid $secondary;
}

.dCheckboxWrapper.success[type='checkbox']:checked + label:after,
.dCheckboxWrapper.success .form-check-input[type='checkbox']:checked + label:after {
  background-color: $success;
  border: 1px solid $success;
}

.dCheckboxWrapper.warning[type='checkbox']:checked + label:after,
.dCheckboxWrapper.warning .form-check-input[type='checkbox']:checked + label:after {
  background-color: var(--warning);
  border: 1px solid var(--warning);
}

.dCheckboxWrapper.danger[type='checkbox']:checked + label:after,
.dCheckboxWrapper.danger .form-check-input[type='checkbox']:checked + label:after {
  background-color: var(--danger);
  border: 1px solid var(--danger);
}

.dCheckboxWrapper.info[type='checkbox']:checked + label:after,
.dCheckboxWrapper.info .form-check-input[type='checkbox']:checked + label:after {
  background-color: var(--info);
  border: 1px solid var(--info);
}

.dCheckboxWrapper.dark[type='checkbox']:checked + label:after,
.dCheckboxWrapper.dark .form-check-input[type='checkbox']:checked + label:after {
  background-color: var(--dark);
  border: 1px solid var(--dark);
}

.dCheckboxWrapper.light[type='checkbox']:checked + label:after,
.dCheckboxWrapper.light .form-check-input[type='checkbox']:checked + label:after {
  background-color: var(--light);
  border: 1px solid var(--dark);
}

.dCheckboxWrapper .disable label:before {
  background-color: rgba(221, 221, 221,0.6);
}

.reset-page {
  max-height: 100vh;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  &__right-item {
    flex-basis: 60%;
    padding: 40px;
    position: relative;
    background: #fff;

    @include media-breakpoint-down(md) {
      padding: 40px 10px;
      z-index: 2;
      background: #fff;
      margin-top: 200px;
      border-top-right-radius: 35px;
      border-top-left-radius: 35px;
      flex-basis: 100%;
      width: 100%;
      position: absolute;
    }

    .logo {
      width: 160px;
      height: 70px;
      margin: 0 auto 25px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .title {
      color: rgb(191, 194, 209);
      text-align: center;
      font-size: 31px;

      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }

      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
    }

    .title2 {
      color: $color-primary;
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      margin-top: 20px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

    .reset-form {
      padding: 40px 200px;

      @include media-breakpoint-down(lg) {
        padding: 40px 100px;
      }

      @include media-breakpoint-down(md) {
        padding: 40px 50px;
      }

      @include media-breakpoint-down(sm) {
        padding: 40px 20px;
      }

      .icons {
        position: absolute;
        left: 3px;
        bottom: 10px;
        font-size: 18px;
      }

      label {
        color: $silver;
      }

      input {
        width: 100%;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $silver;
        padding: 5px 10px 5px 40px;
        background-color: white !important;
        &.disabled {
          opacity: 0.6;
        }
        &:-internal-autofill-selected {
          -webkit-appearance: menulist-button;
          background-color: white !important;
          background-image: none !important;
          color: black !important;
        }
      }

      .link-forget {
        color: $primary;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }

      .btn-reset {
        background-color: $primary;
        color: white;
        width: 100%;
        margin-top: 25px;
        border-radius: 10px;
        border: 0;
        padding: 15px;
        cursor: pointer;
        &.disabled {
          opacity: 0.6;
        }
      }
    }

    .footer-reset {
      color: $silver;
      text-align: center;
      position: absolute;
      font-size: 12px;
      left: 0;
      right: 0;
    }
  }

  &__left-item {
    flex-basis: 40%;
    position: relative;
    @include media-breakpoint-down(md) {
      width: 100%;
      flex-basis: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remember-password {
    position: relative;
    color: black !important;

    input {
      opacity: 0;
      margin: 0 !important;
    }
  }

  [type='checkbox'].filled-in {
    + span:not(.lever):after {
      border-radius: 2px;
    }

    + span:not(.lever):before,
    + span:not(.lever):after {
      content: '';
      right: 0;
      position: absolute;
      transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
        top 0.2s 0.1s, left 0.2s 0.1s;
      z-index: 1;
    }

    &:not(:checked) + span:not(.lever):before {
      width: 0;
      height: 0;
      border: 3px solid transparent;
      right: 10px;
      top: 10px;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }

    &:not(:checked) + span:not(.lever):after {
      height: 15px;
      width: 15px;
      background-color: transparent;
      border: 2px solid $color-primary;
      top: 5px;
      z-index: 0;
      border-radius: 3px;
    }

    &:checked {
      + span:not(.lever):before {
        top: 3px;
        right: 8px;
        width: 6px;
        height: 11px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $gray-100;
        border-bottom: 2px solid $gray-100;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
      }

      + span:not(.lever):after {
        top: 5px;
        height: 15px;
        width: 15px;
        border: 2px solid $sidebar-background;
        background-color: $sidebar-background;
        z-index: 0;
        border-radius: 3px;
      }
    }

    &.tabbed:focus + span:not(.lever):after {
      border-radius: 2px;
      border-color: $sidebar-background;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.tabbed:checked:focus + span:not(.lever):after {
      border-radius: 2px;
      background-color: $sidebar-background;
      border-color: $sidebar-background;
    }

    &:disabled:not(:checked) + span:not(.lever):before {
      background-color: transparent;
      border: 2px solid transparent;
    }

    &:disabled:not(:checked) + span:not(.lever):after {
      border-color: #e1e7ed;
      background-color: #e1e7ed;
    }

    &:disabled:checked + span:not(.lever):before {
      background-color: transparent;
    }

    &:disabled:checked + span:not(.lever):after {
      background-color: $checkbox;
      border-color: $checkbox;
    }

    &:disabled:checked + span {
      cursor: not-allowed;
      color: #999;
    }

    &:disabled:not(:checked) + span {
      cursor: not-allowed;
      color: #999;
    }
  }
}

* {
  outline: none;
}

.main {
  &.collapsed-sidebar {
    @include media-breakpoint-down(sm) {
      margin-left: initial;

      filter: unset;
      .main-content {
        margin-left: 0;
        filter: blur(3px);
      }
      .mobile-header {
        margin-left: 0;
        filter: blur(3px);
      }

      .main-sidebar {
        display: block;
        right: 0;
        .menu-button {
          display: block;
          border: 0;
          background: transparent;
          font-size: 25px;
          color: #d8d8d8;
          padding: 5px;
          cursor: pointer;
        }
        .logo {
          display: none;
        }
        .avatar {
          display: none;
        }
        .name {
          padding-top: 20px;
        }
      }
    }
  }

  .main-sidebar {
    display: none;
    z-index: 11;
    background-color: $sidebar-background;
    transition: all 0.3s;
    position: fixed;
    width: $width-sidebar;
    top: 0;
    bottom: 0;

    @include breakpoint(medium) {
      top: 0;
      display: block;
    }
    .menu-button {
      display: none;
    }
    .inner-scroll {
      direction: ltr;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: lighten($darkblue, 30%);
        border-radius: 10px;
      }

      a {
        color: $sidebar-color;
        &:hover {
          text-decoration: none;
        }
      }

      .logo {
        width: 140px;
        height: 50px;
        overflow: hidden;
        margin: 30px auto 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 25px auto 15px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .name {
        color: $sidebar-color;
        text-align: center;
        font-size: 19px;
      }
      .email {
        color: white;
        text-align: center;
        font-size: 12px;
      }

      // #region content-wrapper
      .content-wrapper {
        width: 100%;
        margin-top: 35px;
        .navigator {
          margin-bottom: 0;
          padding-right: 0;
          list-style: none;
          color: $sidebar-color;

          > .item {
            position: relative;
            margin: 0 10px;

            &.open {
              > .item-title {
                .icon-left {
                  transform: rotate(-90deg);
                  transition: 0.5s;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            > .item-title {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              width: 100%;
              line-height: 25px;
              padding: 5px 0;
              transition: border ease 0.3s, background ease 0.3s;

              @include breakpoint(medium) {
                justify-content: flex-start;
              }

              &::before {
                content: none;

                @include breakpoint(medium) {
                  content: '';
                }
              }

              &[role='button'] {
                i.icon-angle-down {
                  position: absolute;
                  left: 15px;
                }
              }

              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                padding: 0 10px;
                width: 40px;

                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                }

                i {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  font-size: 20px;
                  transition: all 0.3s;

                  &::before {
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    line-height: 35px;
                    font-size: inherit;
                  }
                }
              }

              .text {
                height: 24px;
                font-size: 13px;
                padding: unset;
                text-align: right;
                line-height: 24px;
                position: relative;
                width: calc(75% - 35px);
                overflow: hidden;
                transition: all 0.25s;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .icon-left {
                font-size: 17px;
                transition: 0.5s;
              }
            }

            // #region sidebar
            &.active {
              > .item-title {
                > .text {
                  color: $sidebar-selected-color;
                }

                > .icon {
                  color: $sidebar-selected-color;
                }
              }
            }

            > .show {
              .active {
                color: $sidebar-selected-color;
                .text {
                  color: $sidebar-selected-color;
                }
              }
            }

            .sidebar-subnav {
              padding-left: 48px;
              > .item {
                font-size: 12px;
                padding: 10px 4px;
              }
            }
            // .submenu {
            //     display : block;
            //     width   : 220px;
            //     padding : 0;
            //     position: absolute;
            //     right   : 100%;
            //     top     : 0;

            //     @include themify() {
            //         background-color: #43315f;
            //     }

            //     @include breakpoint(medium) {
            //         display : none; // padding: 10px 0 0;
            //         position: initial;
            //         width   : initial;
            //         top     : initial;
            //         right   : initial;

            //         @include themify() {
            //             background-color: #43315f;
            //         }
            //     }

            //     &::before {
            //         content   : "";
            //         opacity   : 1;
            //         visibility: visible;
            //         width     : 3px;
            //         position  : absolute;
            //         right     : 0;
            //         top       : 0;
            //         height    : 100%;
            //         @include breakpoint(medium) {
            //             opacity   : 0;
            //             visibility: hidden;
            //         }
            //     }

            //     >.item {
            //         list-style: none;

            //         &:last-child {
            //             margin-bottom: 0;
            //         }

            //         .item-title {
            //             cursor     : pointer;
            //             font-size  : .846rem;
            //             display    : block;
            //             line-height: 40px;
            //             position   : relative;
            //             padding    : 5px 60px 5px 5px;

            //             &::before,
            //             &::after {
            //                 content: none;

            //                 @include breakpoint(medium) {
            //                     content: "";
            //                 }
            //             }

            //             &::before {
            //                 content : "";
            //                 right   : 22px;
            //                 top     : calc((100% - 5px) / 2);
            //                 position: absolute;
            //                 width   : 7px;
            //                 height  : 7px;
            //                 border-radius: 2px;
            //             }

            //             &::after {
            //                 content : "";
            //                 right   : 25px;
            //                 position: absolute;
            //                 width   : 1px;
            //                 height  : 100%;
            //             }

            //             &:hover {
            //                 @include themify() {
            //                     background: rgba(themed("white"), 0.06);
            //                 }
            //             }
            //         }
            //     }
            // }

            // #endregion
            // #region expanded menu
            // #endregion
          }
        }

        // #endregion
      }

      // #endregion
      // #region collapsed sidebar
    }

    &.collapsed {
      display: block;

      @include breakpoint(large) {
        width: 70px;
      }

      .header {
        .logo {
          .text {
            opacity: 0;
          }
        }
      }

      .content-wrapper {
        .navigator {
          > .item {
            &::before {
              display: none;
            }

            > .item-title {
              display: flex;

              i.icon-angle-down {
                display: none;
              }

              .text {
                // width: 0;
                @include breakpoint(large) {
                  opacity: 0;
                }

                // @include textOverflow();
                // padding: unset;
                // font-size: 9px;
                // text-align: center;
              }

              .icon-left {
                @include breakpoint(large) {
                  opacity: 0;
                }
              }

              span.icon > i {
                font-size: 24px;
              }

              .badge-count {
                position: absolute;
                top: 10px;
                left: 10px;
                margin: 0 !important;
              }
            }

            .submenu {
              display: block;
              position: initial;
              width: initial;
              top: initial;
              right: initial;

              @include breakpoint(large) {
                width: 220px;
                padding: 0;
                position: absolute;
                right: 100%;
                top: 0;
              }

              @include themify() {
                background-color: #43315f;
              }

              &::before {
                opacity: 1;
                visibility: visible;
              }

              > .item {
                .item-title {
                  // padding: 5px 15px;
                  &::before,
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .collapse-icon {
      &.active {
        color: $primary;
      }
    }
    // #endregion
  }
}

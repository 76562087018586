.workingHours {
  position: relative;
  &.editor {
    border: 2px solid #eee;
    border-radius: 3px;
  }

  .fa-pencil-alt {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 20px;
    color: #888;
  }

  .items {
    font-size: 12px;
    line-height: 2;
    div {
      &:first-child {
        width: 25%;
      }
    }
  }

  table {
    max-width: 800px !important;
    width: 100%;
    @include media-breakpoint-down(sm) {
      max-width: 550px !important;
      width: auto;
    }
    th {
      height: 40px;
      line-height: 40px;
      padding: 0 17px;
      border-bottom: 1px solid rgba(200, 200, 200, 0.6);
      width: 10%;
      white-space: nowrap;
      @include media-breakpoint-down(sm) {
        font-size: 11px;
      }
      &.hoursList {
        width: 55%;
      }
      &:last-child {
        width: 15%;
      }
    }
    td {
      min-height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(200, 200, 200, 0.2);
      padding: 1px 17px;
      white-space: nowrap;
      .datepicker-input {
        border: 0;
        border-bottom: 1px solid #bbb;
        line-height: 30px;
        width: 65%;
        text-align: center;
        border-radius: 0;
      }
      .react-datepicker-wrapper {
        width: 60%;
        line-height: 30px;
        .react-datepicker__input-container {
          input {
            border: 0;
            border-bottom: 1px solid #bbb;
            text-align: center;
            width: 100%;
            min-width: 85px;
          }
        }
      }

      &:last-child {
        vertical-align: bottom;
      }
      .switch {
        margin-bottom: 0;
      }
      .timeRange {
        border-radius: 3px;
        background-color: $primary;
        display: block;
        line-height: 24px;
        padding: 0 7px;
        color: #333;
        margin: 0 10px 4px;
        max-width: 250px;
        @include media-breakpoint-down(sm) {
          min-width: 120px;
          padding: 0 2px;
          margin: 2px 0;
        }
        .hidden {
          display: none;
        }
        .rw-dropdown-list {
          flex-grow: 1;
          margin: 5px;

          @include media-breakpoint-down(sm) {
            margin: 1px;
            font-size: 12px;
          }
          .rw-widget-container {
            max-width: 90px;
          }
          &.invalid {
            .rw-widget-container {
              border: 1px solid #f00;
            }
          }
          .rw-widget-picker {
            > .rw-select {
              span {
                margin-right: 0;
              }
            }
          }
          .rw-input {
            @include media-breakpoint-down(sm) {
              padding: 0;
              font-size: 12px;
            }
          }
        }
        &.hide {
          display: none;
        }
        &.addHours {
          opacity: 0.8;
        }
        span {
          margin-right: 10px;
          @include media-breakpoint-down(sm) {
            margin-right: 1px;
          }
          &.rw-select {
            > * {
              width: 0.8em;
            }
          }
          i {
            font-size: 11px;
            color: rgba(50, 50, 50, 0.8);
            margin-left: 5px;
            cursor: pointer;
          }
          &.rc-time-picker {
            margin: 0 5px;
            border: 0;
            width: auto;

            &:hover {
              background-color: transparent;
            }

            .rc-time-picker-clear {
              right: 0;
              line-height: 25px;
            }
            .rc-time-picker-input {
              width: 100px;
            }
            .rc-time-picker-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  &.specialHours {
    table {
      th {
        &:first-child {
          width: 20%;
        }
        &.hoursList {
          width: 35%;
        }
        &:last-child {
          width: 25%;
        }
      }
    }
    .iconBtn {
      //color: $primary;
      font-weight: 500;
    }
  }
}

.rc-time-picker-panel-input,
.rc-time-picker-panel-input:hover {
  border: 1px solid transparent;
  direction: ltr;
}

.iconBtn {
  font-size: 12px;
  cursor: pointer;
  color: $primary;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    margin: 0 2px !important;
  }
}

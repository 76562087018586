@import url(//fonts.googleapis.com/css?family=Roboto:400italic,700italic,400,700);

// Fonts
$roboto: 'Roboto', serif;


// Helpers -- do not use in other scss files
$gold: #d2ab67;
$navi: #26247b;
$darkblue: #343951;
$blue: #007bff;
$green: #1abb9c;
$gray: #ced4da;
$light-gray: #ddd;
$silver: #bfc2d1;
$white: #fff;
$black: #000;
$background: #f0f3f6;

// Variables
$color-primary: $navi;
$primary: $gold;
$secondary: $darkblue;
$success: $primary;
$danger: $primary;
$info: $primary;
$warning: $primary;
$on-prime: var(--on-prime);
$none: transparent;
$light: $gray;
$border: var(--border);
$link: darken($darkblue, 10%);
$checkbox: lighten($darkblue, 15%);

$main-background: var(--main-background);
$content-background: var(--content-background);
$sidebar-background: var(--sidebar-background);
$sidebar-selected-color: var(--sidebar-selected-color);
$sidebar-color: var(--sidebar-color);

$width-sidebar: var(--width-sidebar);
$theme-radius: var(--theme-radius);
$input-placeholder-color: var(--imput-placeholder-color);

$footer-text-color: var(--footer-text-color);
$footer-link-color: var(--footer-link-color);
$footer-background: var(--footer-background);
$footer-border-top: var(--footer-border-top);

// Light/Dark Themes -- For future
$themes: (
  light: (
    'white': $white,
    'black': $black,
    'primary': $darkblue,
    'secondary': $green,
    'blue01': $blue,
    'content-background': $content-background,
  ),
  dark: (
    'white': $white,
    'black': $black,
    'primary': $darkblue,
    'secondary': $green,
    'blue01': $blue,
    'content-background': $content-background,
  ),
);


// Icons
$icomoon-font-family: 'custom-icons' !default;
$icomoon-font-path: './fonts/font-icon' !default;

$icon-at: '\e923';
$icon-calendar1: '\e924';
$icon-mobile-phone: '\e925';
$icon-Shape: '\e926';
$icon-tag: '\e901';
$icon-new-1: '\e910';
$icon-new-2: '\e903';
$icon-mark: '\e908';
$icon-info: '\e921';
$icon-refund: '\e922';
$icon-close: '\e905';
$icon-diamond: '\e907';
$icon-calendar: '\e906';
$icon-star: '\e90a';
$icon-storage: '\e90b';
$icon-support: '\e90c';
$icon-user: '\e90d';
$icon-www: '\e90e';
$icon-minus: '\e90f';
$icon-order: '\e911';
$icon-password: '\e912';
$icon-pencil: '\e913';
$icon-plus-1: '\e914';
$icon-plus-2: '\e915';
$icon-plus-3: '\e916';
$icon-product: '\e917';
$icon-protest: '\e918';
$icon-bill-1: '\e920';
$icon-bill-2: '\e919';
$icon-bin: '\e91a';
$icon-gear: '\e91b';
$icon-dashboard-1: '\e91c';
$icon-dashboard-2: '\e91d';
$icon-dashboard-3: '\e91e';
$icon-dashboard-4: '\e91f';
$icon-bookmark: '\e900';
$icon-menu: '\e909';
$icon-search: '\e904';
$icon-check: '\e902';
$icon-bell-o: '\f0a2';
$icon-angle-left: '\f104';
$icon-angle-right: '\f105';
$icon-angle-down: '\f107';

$content-margin: 4.5rem;

.product-page {
  .number-wrapper {
    flex-shrink: 1;
    max-width: 9rem;
    .dInputWrapper {
      input {
        min-height: 42px;
      }
    }
  }
  .auto-width {
    width: auto !important;
  }
  .score-wrapper {
    flex-shrink: 1.5;
    min-width: 4rem;
  }
}

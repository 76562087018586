.info-page {
  .row-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 2rem;
    @include breakpoint(large) {
      display: none;
    }
    &__title {
      font-size: 20px;
    }
    .title {
      display: flex;
      font-size: 16px;
      font-weight: 500;

      p {
        padding: 0 5px;
      }
    }
  }

  &__box-right {
    background-color: white;
    padding: 25px 20px;
    border-radius: 10px;
    height: unset;
    margin: 0 1rem;
    @include breakpoint(medium) {
      margin: 0 25px;
    }
    @include breakpoint(large) {
      margin: 0 0 2rem;
      border-radius: 25px;
      min-height: 95vh;
    }

    &__title {
      font-size: 17px;
      margin-bottom: 25px;
      display: none;
      @include breakpoint(large) {
        display: block;
      }
    }

    .top-box {

      .image {
        border: 2px solid $primary;
        margin: 10px auto 15px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          width: 85%;
          height: 85%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .name {
        color: $primary;
        font-size: 16px;
        text-align: center;
      }

      .number-mobile {
        font-size: 14px;
        text-align: center;
        width: 100%;
        border: none;
      }

      .score {
        &.btn-primary {
          &:hover, &.active, &:focus{
            background-color: $primary !important;
            box-shadow: unset !important;
          }
        }
        &.btn-secondary {
          &:hover, &.active, &:focus{
            background-color: $secondary !important;
            box-shadow: unset !important;
          }
        }
        font-weight: bold;
        cursor: default;
      }
    }

    .bottom-box {
      .item {
        margin-bottom: 1rem;

        i {
          color: #9b9da7;
          margin-left: 7px;
          font-size: 18px;
        }

        .title {
          color: #9b9da7;
          margin-bottom: 0;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }

    .rank {
      background-color: #8bc34a;
      color: white;
      font-weight: bold;
      border-radius: 15px;
      text-align: center;
      padding: 5px;
      cursor: default;

      &:hover, &.active, &:focus{
        background-color: #8bc34a !important;
        box-shadow: unset !important;
        color: white;
      }
    }
  }

  &__box-left {
    padding: 0 1rem;
    width: 100%;
    @include breakpoint(medium) {
      padding: 0 1.5rem;
    }

    .box-mobile {
      background-color: white;
      padding: 20px;
      border-radius: $theme-radius;
      height: 100%;

      &__title {
        font-size: 20px;
        margin-bottom: 25px;
      }

      .prefixNumber {
        margin-right: -30px;
        z-index: 1;
        color: #356;
      }

      .show-number {
        display: flex;
        flex-direction: column;
        text-align: center;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px;

        label {
          color: gray;
          margin: 0;
        }

        .price {
          border: 0 !important;
        }

        input {
          border: 0;
          width: 135px;
          text-align: center;
          margin: 0 auto;
          padding: 10px 10px 10px 30px;
        }
      }

      .box-numbers {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        direction: ltr;

        .item {
          flex-basis: 30%;
          margin: 15px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            width: 60px;
            height: 60px;
            font-weight: bold;
            background-color: rgb(244, 243, 248);
            border-radius: 50%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            cursor: pointer;
          }
        }
      }

      .btn-mobile {
        background-color: $primary;
        color: white;
        width: 100%;
        border: 0;
        padding: 15px;
        border-radius: 10px;
        font-size: 18px;
        margin-top: 25px;
        cursor: pointer;

        &.disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }

    .title {
      display: flex;
      font-size: 16px;
      font-weight: 500;

      p {
        padding: 0 5px;
      }
    }

    .title-order {
      margin: 1rem 0 0;
      font-size: 19px;
    }

    .btn-order {
      color: $primary;
      background-color: white;
      border: 1px solid $primary;
      border-radius: 15px;
      padding: 4px 10px;
      cursor: pointer;
    }

    .box-center {
      background-color: white;
      padding: 15px;
      border-radius: 10px;
      margin-top: 15px;
      height: unset;
      border: 1px solid $border;

      @include breakpoint(large) {
        height: 100%;
      }

      .reward {
        margin: 10px auto;
        padding: 10px;
        border-radius: 0.25rem;
        border: 2px solid #f4f3f8;
        background-color: #f4f3f8;
        max-width: 400px;
        cursor: pointer;

        &:hover {
          background-color: #e7e5ef;
        }

        &.deactive {
          background: #fafafa;
          color: #cccccc;
          cursor: auto !important;
        }

        span {
          font-size: 1.2rem;
        }

        .check {
          width: 1.5rem;

          i.fa-check {
            vertical-align: sub;
            border: 1px solid var(--border);
            padding: 3px;
            border-radius: 3px;
            color: var(--secondary);
            background-color: white;
          }

          i.unCheck:before {
            visibility: hidden;
          }
        }
      }

      .rewardHeader {
        margin: 10px auto;
        padding: 6px;
        border-radius: .25rem;
        max-width: 400px;
        color: $primary;

        .title {
          margin-right: 1.75rem;
        }

        .redeemed_points {
          margin-left: 20px;
          color: $primary;
          display: flex;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .personalReward {
        margin: 10px auto;
        padding: 10px;
        background: lighten($secondary, 20%);
        max-width: 400px;
        color: #fff;

        &.deactive {
          background: #f5f5f5;
          color: #bdbdbd;
          cursor: auto!important;
        }

        span {
          font-size: 1.2rem;
        }

        .check {
          width: 50px;
        }
      }

      .personalReward:not(.deactive) {
        cursor: pointer;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px dashed #a1acb7;

        &:last-child {
          border: 0;
        }

        &__rigth {
          &__title {
            margin: 0;
            font-weight: bold;
            font-size: 17px;
          }

          &__price {
            margin: 0;
            font-size: 13px;
            color: #9b9da7;
          }
        }

        &__left {
          &__title {
            margin: 0;
            font-size: 13px;
            color: #9b9da7;
          }

          &__counter {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            //@include themify() {
            //  background-color: themed('content-background');
            //}
            background-color: $content-background;
            border-radius: 50px;
            margin-top: 5px;
            align-items: center;

            .number {
              font-size: 17px;
            }

            .positive {
              background-color: white;
              padding: 2px 13px;
              border-radius: 50%;
              font-size: 22px;
              color: #9b9da7;
              box-shadow: 0 0 6px -2px grey;
              cursor: pointer;
            }

            .negative {
              background-color: white;
              padding: 3px 16px;
              border-radius: 50%;
              font-size: 22px;
              color: #9b9da7;
              box-shadow: 0 0 6px -2px grey;
              cursor: pointer;
            }
          }
        }
      }

      .check {
        width: 1.5rem;

        i.fa-check {
          vertical-align: sub;
          border: 1px solid var(--border);
          padding: 3px;
          border-radius: 3px;
          color: var(--secondary);
          background-color: white;
        }

        i.unCheck:before {
          visibility: hidden;
        }
      }
    }

    .box-end {
      background-color: white;
      padding: 15px;
      border-radius: 10px;
      margin-top: 1rem;
      height: unset;
      position: relative;
      border: 1px solid $border;

      //@include breakpoint(large) {
      //  height: 100%;
      //}

      .summary-actions {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }

      .table {
        background-color: white;
        text-align: center;

        &.receipt {
          tbody {
            tr {
              border: 0;
            }

            .receipt-items {
              border-bottom: 1px dotted #dedede;

              &:last-child {
                border: 0;
              }
            }

            .topBorder {
              border-top: 1px dashed #a1acb7;
            }
          }
        }

        thead {
          background-color: #f7f8f9;

          th {
            padding: 5px;
            text-align: center;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid var(--border);
          }
        }

        td {
          font-size: 15px;
          padding: 0.5rem;
        }
      }

      .div-end {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 15px;

        .text {
          margin: 0;
          font-size: 15px;

          .number {
            font-weight: bold;
            font-size: 20px;
          }

          .price {
            font-size: 14px;
          }
        }

        .btn-save {
          color: white;
          background-color: $primary;
          border-radius: 5px;
          border: 0;
          padding: 10px 10px;
          flex-basis: 80%;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .btn-delete {
          background-color: white;
          border: 1px solid $silver;
          padding: 8px 8px;
          flex-basis: 15%;
          font-size: 22px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          justify-content: center;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .btn-print {
      background-color: white;
      border: 1px solid $silver;
      padding: 8px 8px;
      flex-basis: 15%;
      font-size: 16px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: center;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .fa-print {
        color: $silver;
      }
    }

    .addProductBtn {
      width: 12rem;

    }

    &.oldversion {
      .box-end {
        margin-top: 5px !important;
        table {
          td {
            padding: 0.5rem;
          }
        }

        .btn-save {
          width: calc(100% - 1rem);
        }
      }

    }
    @include media-breakpoint-down(xs) {
      &.oldversion {
        width: calc(100% - 1rem);
        padding-right: 1.5rem;
        padding-left: 1rem;
      }
      .box-end {
        margin-top: 15px;
      }
    }
    @include media-breakpoint-down(sm) {
      &.oldversion {
        width: 100% ;
        padding-right: 1.5rem;
      }
      .box-end {
        margin-top: 15px;
      }
    }
  }

  .btn-secondary {
    color: $on-prime !important;
  }

  .icon-bin:before {
    color: $secondary;
  }

  .btn-outline-secondary:hover {
    background-color: inherit !important;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .visitPointMod {
    margin-top: -2rem;
  }
}

.rtl  {
  .info-page {
    .oldversion {
      .btn-save {
        margin-left: 1rem;
      }
    }
    .doneBtn {
      margin-left: 1rem;
    }
    .price-wrapper {
      text-align: left;
    }
  }
  .terminal-price {
    direction: rtl;
  }
  .addProductBtn {
    margin-right: calc(50% - 6rem);
  }

}
.ltr  {
  .info-page {
    .oldversion {
      .btn-save {
        margin-right: 1rem;
      }
    }
    .doneBtn {
      margin-right: 1rem;
    }
    .price-wrapper {
      text-align: left;
    }
  }
  .terminal-price {
    direction: ltr;
  }
  .addProductBtn {
    margin-left: calc(50% - 6rem);
  }
}


@media print {
  .info-page__box-left .box-end .table td {
    font-size: x-large;
  }
  .box-end  {
    font-size: x-large;
  }
  .receipt-items {
    font-size: x-large;
  }
  .d-none.d-print {
    display: block !important;
  }
}

.ltr .info-page {
  .box-end table td{
    &:nth-child(2) {
      text-align: center;
    }
    &:first-child {
      text-align: center;
    }
    &.text-center{
      div.total {
        text-align: end !important;
      }
    }
  }
  .table-details {
    tbody {
      tr {
        td:last-child {
          text-align: right !important;
        }
      }
    }
  }
}

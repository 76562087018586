.autopilot-page {
  .show-panel {
    h4 {
      width: 5rem;
    }
  }
  table {
    width: 100%;
    thead {
      th {
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
      }
    }
    tbody {
      td {
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
        div {
          font-size: 12px;
          &.text-description {
            color: $gray-500;
            margin: 8px 0 0;
            font-size: 11px;
          }
        }
      }
    }
  }

  .stats {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }

  .statsDetails {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }

  .box {
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    h3 {
      width: 30%;
      font-size: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 11px;
      }
    }
  }
  .accordion {
    margin: 5px auto;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.04);
    .accordionTitle {
      h4 {
        font-size: 16px;
        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
      }
    }
    .accordionContent {
      padding: 1rem 0;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin-top: -1rem;
      h4 {
        width: 25%;
      }
    }
  }

  .dSelectBoxWrapper div[class$="-Input"],
  .dSelectBoxWrapper div[class*="-Input "] {
    padding: 0 5px;
  }

  div[class$="-indicatorContainer"],
  div[class*="-indicatorContainer "] {
    padding: 0 !important;
  }

  .inlineSelect {
    margin-top: -0.2rem;

    div[class$="-indicatorContainer"],
    div[class*="-indicatorContainer "] {
      padding: 0 !important;

      svg {
        width: 15px;
        height: 15px;
        padding-top: 2px;
      }
    }
  }

  .autopilot-accordion {
    .card-header {
      border-bottom: none;
      background-color: $white;
      padding: 0.75rem 0;
    }

    .card-body {
      padding-top: 0;
    }
    .accordion-body {
      background: rgba(0,0,0,.05);
      border-radius: 5px;
      td {
        text-align: center;
        width: 24%;
      }
      .invisible {
        width: 25%;
      }
    }
    .accordion-header {
      td {
        //text-align: center;
      }
    }
  }

  .separator {
    border-bottom: 1px solid #bfc2d1 !important;
    margin: 10px !important;
  }

  .campaignResult {
    min-width: 3rem;
    padding-top: 0.3rem;
  }

  .flex-grow-2 {
    flex-grow: 2;
  }

  .exceptionExpire {
    font-size: 12px;
  }

  .disableRow {
    background-color: #eae9e9;
    color: rgba(0,0,0,0.5);
  }

  .autopilot-title {
    td {
      text-align: center;
    }
  }

  .nth-1 {
    width: 25%;
  }
  .nth-1-body {
    width: 25%;
  }
  .card-body {
    overflow-x: auto;
  }
}

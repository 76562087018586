.promotion-page{
  a {
    cursor: pointer;
    text-decoration: underline !important;
    i {
      color: $secondary;
    }
  }

  hr {
    border-bottom: 1px solid #bfc2d1 !important;
    margin: 1rem 0 !important;
  }
}

.chatBox-page {
  background-color: #fff;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 5px;

  .chat-box-wrapper {
    border: 1px solid #ccc;
    margin: 0;
    border-radius: 5px;
  }
  .chat-list-wrapper {
    padding: 0;
  }
  hr {
    margin: 0 !important;
    border-style: solid !important;
  }
  .chatItemContainer {
    position: relative;
  }
  .chat-list {
    height: 100%;
    padding: .5rem .125rem .5rem .4375rem;
    overflow-y: hidden;
    min-height: 70vh;
    &:hover {
      overflow-y: auto;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .list-item {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    letter-spacing: 0;

    .info {
      width: 100%;
      margin-top: -0.25rem;
    }
  }
  .list-item-btn {
    padding: .5625rem 0;
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    display: flex;
    position: relative;
    overflow: hidden;
    line-height: 1.5rem;
    white-space: nowrap;
  }
  .title {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: initial;
    unicode-bidi: plaintext;

    h3 {
      width: auto;
      max-width: 80%;
      font-size: 1rem;
      line-height: 1.6875rem;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .LastMessageMeta{
      padding: .3rem 0 .15rem;
      flex-shrink: 0;
      font-size: .75rem;
      line-height: 1;
      display: flex;
      align-items: center;

      span {
        line-height: 1.15rem;
        font-size: 0.6rem;
      }
    }
  }
  .subtitle {
    margin-top: -0.125rem;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.7;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .sb-avatar__text {
    >div{
      font-size: 20px !important;
    }
  }
}

.main-messagebox {
  display: flex !important;
  background-color: #e6ebee;
  width: 100%;
  position: relative;
  flex: 3;
  transition: .25s ease-in-out;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  flex-direction: column;
  grid-row-start: 1;
  grid-column-start: 1;
  overflow: hidden;

  .tab-container {
    min-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 100%;
  }
  .chat {
    display: flex;
    flex-direction: column;
    grid-row-start: 1;
    grid-column-start: 1;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    align-items: center;
    transition: transform var(--tabs-transition),filter var(--tabs-transition);

    .chat-background {
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      background-color: #e6ebee;

      .chat-background-item {
        margin: -18.5rem 0 -22rem 0;
        transform: scale(1);
        transform-origin: left center;
        transition: transform .25s cubic-bezier(.4, .0, .2, 1),opacity .25s cubic-bezier(.4, .0, .2, 1) !important;
        //background-image: url(assets/img/bg.jpeg);
        background-size: cover;
        background-position: center center;
        background-color: inherit;
        position: absolute !important;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  .sidebar-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    min-height: 3.5rem;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    --topbar-height: 3.5rem;
    --pinned-floating-height: 0px;
    --pinned-audio-height: 52px;
    --pinned-message-height: 52px;
    width: 100%;
    z-index: 1;
    position: relative;
    cursor: pointer;

    &:before {
      content: " ";
      position: absolute;
      //height: calc(var(--topbar-height) + var(--pinned-floating-height));
      top: 0;
      right: 0;
      left: 0;
      //box-shadow: 0 1px 5px -1px rgb(0 0 0 / 21%);
      pointer-events: none;
    }
  }

  .chat-info {
    flex: 1 1 auto;
    overflow: hidden;
    padding-left: 49px;
    max-width: calc(100% - 96px);

    .person {
      display: flex;
      align-items: center;
      .content {
        flex: 1 1 auto;
        max-width: 100%;
        overflow: hidden;
      }

      .user-title {
        font-size: 1rem;
        line-height: 1.5rem;
        max-width: calc(100% - 1.5rem);
        display: flex;
        align-items: center;
      }

      .info {
        margin-top: 1px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.3125;
        font-size: .875rem;
        color: #707579;
      }
    }
  }

  .chat-utils {
    position: relative;
    z-index: 1;
    transition: transform .25s cubic-bezier(.4, .0, .2, 1);
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    button {
      border: none;
      background: inherit;
      color: #707579;
      cursor: pointer;
      font-size: 0.85rem;
    }
  }

  .bubble {
    --translateY: 0;
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    transform: translate3d(0, var(--translateY), 0);
    transition: transform .25s cubic-bezier(.4, .0, .2, 1);

    .scrollable {
      overflow-y: auto;
      height: auto;
      display: block;

      width: 100%;
      overflow-x: hidden;
      max-height: 100%;
      transform: translateZ(0);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      .bubble-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        min-height: 100%;
        justify-content: flex-end;
        padding: 0 0.8125rem;
        max-width: 728px;
        transition: transform .25s cubic-bezier(.4, .0, .2, 1);
        transform: translateY(0);

        .bubble-text {
          position: relative;
          z-index: 1;
          margin: 0 auto 0.25rem;
          user-select: none;
          display: flex;
          flex-wrap: wrap;
        }
        .bubble-content-wrapper {
          width: 85%;
          transition: transform .25s cubic-bezier(.4, .0, .2, 1),opacity .25s cubic-bezier(.4, .0, .2, 1);
          transform: scale(1) translateX(0);
          transform-origin: center;
          opacity: 1;

          .bubble-content {
            border-radius: 6px 12px 12px 6px;
            min-width: 56px;
            max-width: 100%;
            //box-shadow: 0 1px 2px 0 rgb(16 35 47 / 15%);
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            z-index: 2;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #fff;

            .message {
              max-width: 480px;
              font-size: 16px;
              padding: 6px 2.5rem 0.375rem 0.625rem;
              color: #000;
              line-height: 1.3125;
              word-break: break-word;
              white-space: pre-wrap;
              position: relative;

              .time {
                margin-left: -3px;
                padding-right: 8px;
                visibility: hidden;
                font-size: 12px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                line-height: 1;
                vertical-align: middle;
                pointer-events: none;
                display: inline-flex;
                z-index: 1;
                height: 12px;
                direction: ltr;
                float: right;
                cursor: pointer;

                .inner {
                  color: #707579;
                  margin-bottom: 4px;
                  pointer-events: all;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  line-height: 1;
                  padding: inherit;
                  white-space: nowrap;
                  height: 12px;
                  visibility: visible;
                }
              }
            }

            .bubble-tail {
              margin-left: -8.4px;
              position: absolute;
              width: 11px;
              height: 20px;
              z-index: -2;
              display: block;
              fill: #fff;
              transform: translateY(1px);
            }
          }
        }
      }
    }

    .sender {
      direction: ltr;
      .bubble-content {
        border-radius: 6px 12px 12px 6px !important;
      }
    }
    .receiver {
      direction: rtl;
      .bubble-content {
        border-radius: 12px 6px 6px 12px !important;
      }
    }
  }

  .composer {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 0.5rem;
    border-top: 1px solid #ccc;

    .message-input {
      width: 100%;
      padding: 0 0.5rem 0.25rem;
    }
    textarea {
      padding: 0 0.75rem;
    }
  }
}

.rtl {
  .chatBox-page {
    .LastMessageMeta {
      margin-left: .2rem;
      margin-right: auto;
    }
  }
  .chat-list-wrapper {
    border-left: 1px solid #ccc;
  }
}
.ltr .chatBox-page{
  .LastMessageMeta {
    margin-right: .2rem;
    margin-left: auto;
  }
}

.dashboard-page {
  .logo {
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    height: 74px;
    width: 74px;
    object-fit: cover;
  }
  .legend {
    position: absolute;
    bottom: 0;
    width: 100%;
    > div {
      display: none;
    }
    tbody {
      display: flex;
      tr {
        margin-right: 10px;
      }
    }
  }
  .box-item {
    flex-basis: 30%;
    border-radius: 10px;
    background-color: rgb(245, 246, 250);
    padding: 1rem 0 0;
    height: 6rem;
    position: relative;
    border: 1px solid $border;

    @include breakpoint(xsmall) {
      margin-bottom: 4px;
    }
    .title {
      font-size: 14px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
    .description {
      font-size: 12px;
      margin-bottom: 0;
      margin-right: 5px;
    }
    .sum-box {
      background-color: $primary;
      position: absolute;
      border-radius: 10px;
      bottom: 0;
      left: 0;
      right: 0;
      &.sum-1 {
        height: 3rem;
      }

      .box-number {
        color: white;
        border-radius: 15px;
        margin: 0 auto;
        position: absolute;
        left: 10px;
        right: 10px;
        padding: 3px 5px;
        font-size: 1rem;
        bottom: 10px;
        text-align: center;
      }
    }
  }
  .select-box {
    width: 100%;
    margin-left: 0.5rem;
    //.css-yk16xz-control{
    //  border-radius: 7px;
    //  min-height: unset;
    //}
  }

  .selectbox-wrapper {
    min-width: 15rem;
  }

  .box-filter {
    background-color: white;
    // border-radius   : 5px;
    // box-shadow      : 0px 1px 6px -4px grey;
    margin-right: 15px;
    display: flex;
    align-items: center;
    padding: 8px 4px;
    border: 1px solid #c5c5c5;
    border-radius: 7px;

    @include breakpoint(xsmall) {
      margin-right: 0;
    }
    span {
      padding: 2px 15px;
      cursor: pointer;

      @include breakpoint(xl) {
        padding: 2px 15px;
      }

      @include media-breakpoint-down(lg) {
        padding: 2px 10px;
      }
      &.active {
        color: white;
        background-color: $primary;
        border-radius: 5px;
      }
    }
  }

  .box-gray {
    border-radius: 10px;
    background-color: #f5f6fa;
    display: inline-flex;
    align-items: center;
    padding: 0 10px 0 0;
    font-size: 12px;
    > div {
      margin-left: 8px;
    }

    .box-progress {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 5px;
      margin: 0;

      .box-value {
        border-radius: 15px;
        background-color: $primary;
        color: $on-prime;
        padding: 5px;
        margin-top: 5px;
      }
    }
    .box-container {
      display: flex;
      flex-direction: column;
      .box-items {
        text-align: center;
        background-color: $primary;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 5px;
        color: #fff;
        flex-basis: 25%;
        &:first-child {
          margin-bottom: 1px;
        }
        .box-value {
          background-color: $primary;
          border-radius: 15px;
          padding: 5px 30px;
          margin: 4px;
        }
        .box-title {
          padding: 5px;
        }
      }
    }
  }

  .recharts-responsive-container {
    position: relative;
    &.loadingChart {
      &:before {
        background: #fff;
        width: 100%;
        height: 100%;
        z-index: 2;
        content: ' ';
        display: block;
        opacity: 0.8;
        position: absolute;
      }
    }
  }

  .signupGoalInput {
    width: 50px;
    padding: 0 !important;
    text-align: center;
  }

  .dSelectBoxWrapper div[class$="-Input"],
  .dSelectBoxWrapper div[class*="-Input "] {
    padding: 0 5px;
  }
  .dSelectBoxWrapper div[class$="-singleValue"],
  .dSelectBoxWrapper div[class*="-singleValue "] {
    font-size: 0.85rem;
  }

  .summary-box {
    height: 2.8rem;
    padding: 12px 6px;
    border: 1px solid #c5c5c5;
    border-radius: 7px;

    h6 {
      font-size: 0.85rem;
      font-weight: normal;
    }
  }

  .signupGoalDuration {
    width: 7rem;
  }

  .singUpGoalBtn {
    height: 27px;
    font-size: 14px;
  }
}

// Please use files in theme folder to overwrite themes

@import 'variables';
@import 'bootstrap-4.1.3/bootstrap';
@import 'bootstrap-4.1.3/rtl-bootstrap';
@import '../components/elements/elementsStyle';
@import 'fonticon';
@import 'fontiran';
@import 'mixin';
@import 'media';
@import 'reset';
@import 'button';
@import 'lang';

// Global Codes
@import 'main';
@import 'ltr';

// Pages
@import './pages/login';
@import './pages/register';
@import './pages/resetpassword';
@import './pages/home';
@import './pages/customer.registration';
@import './pages/customers';
@import './pages/report';
@import './pages/info';
@import './pages/dashboard';
@import './pages/billing';
@import './pages/autopilot';
@import './pages/workinghours';
@import './pages/import';
@import './pages/announcements';
@import './pages/profile';
@import './pages/webpage';
@import './pages/feature';
@import './pages/referral';
@import './pages/firsLogin';
@import './pages/product';
@import './pages/survey';
@import './pages/faq';
@import './pages/message';
@import './pages/chat';
// Components

@import 'components/header';
@import 'components/sidebar';
@import 'components/footer';
@import 'components/pagination';
@import 'components/switch';
@import 'components/datepicker';

@import './transaction';
//@import 'components/confirmModal';

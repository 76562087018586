.first-login-page {
  background-color: white;
  min-height: 100vh;
  width: calc(100% - #{$width-sidebar});

  .header {
    background-color: $sidebar-background;
  }

  hr {
    border-top: 2px solid $sidebar-background;
  }

  .icon {
    font-size: 2rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .description {
    font-size: 0.6rem;
    font-weight: 500;
    text-align: center;
  }

  button {
    margin: 1rem;
  }
}

.rtl .first-login-page {
  .section-one {
    border-left: 2px solid $sidebar-background;
  }
}
.left .first-login-page {
  .section-one {
    border-right: 2px solid $sidebar-background;
  }
}
@include media-breakpoint-up(sm) {
  .first-login-page {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 3.5rem;
    .header {
      display: none;
    }

    .content {
      margin-top: 1rem;
    }
  }
}

@include media-breakpoint-up(xs) {
  .first-login-page {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 3.5rem;
    .header {
      display: none;
    }

    .content {
      margin-top: 1rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .section-one {
    border: none !important;
  }
}

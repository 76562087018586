.announcements-page {
  .icon-check {
    color: $green;
  }
  .icon-calendar1:before {
    color: $primary;
  }
  .method {
    color: $gray-600;
    font-size: 0.75rem;
  }
  .description {
    font-size: 0.75rem;
    color: $gray-400;

    .title {
      font-size: 0.9rem;
      color: $black;
    }
  }
  .logo {
    border-radius: 4px 4px 0 0;
    border: 1px solid $border;
    margin-top: .75rem;
    width: 150px;
    padding: .5rem;
    position: relative;
  }
  .logo img {
    width: 100%;
    border-radius: 4px;
  }
  .delete-logo {
    position: absolute;
    left: 1px;
    top: 0;
    color: var(--danger);
    cursor: pointer;
  }
  .uploadBtn {
    //bottom: -1.5rem;
    width: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .deliveryTime {
    min-width: 70px;
  }
  .datepicker-input.outline {
    height: 45px;
    width: 100%;
  }
  .announcementList table thead tr th:nth-child(2){
    width: 50% !important;
  }

  .announcement-email {
    .dSelectBoxWrapper.outline div[class$="-control"] {
      min-height: 45px;
    }
  }
}
.rtl {
  .announcements-page {
    .timepicker-section {
     float: right;
    }
  }
  .hour {
    margin-right: 1rem;
  }
}
.ltr {
  .announcements-page {
    .timepicker-section {
     float: left;
    }
  }
  .hour {
    margin-left: 1rem;
  }
}

body {
  overflow-y: auto;
  font: inherit;
}

.ltr {
  direction: ltr;
  text-align: left;
  [class^='modal---modal-overlay'] {
    [class*='modal---modal-content'] {
      max-width: 600px;
      [class^='modal---close'] {
        right: 24px;
        left: auto;
      }
    }
  }

  [type='checkbox'].filled-in + span:not(.lever):before,
  [type='checkbox'].filled-in + span:not(.lever):after {
    right: auto;
    left: 0;
  }

  [type='checkbox'] + span {
    padding-left: 25px;
    padding-right: 0px;
    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      line-height: 20px;
    }
  }

  .modalFooter .modalMore {
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  .MuiTypography {
    font-family: Arial, Helvetica, sans-serif;
  }
  .MuiInput-root {
    font-family: Arial, Helvetica, sans-serif;
  }
  .main {
    &.collapsed-sidebar {
      @include media-breakpoint-down(md) {
        .main-sidebar {
          left: 0;
          right: auto;
          .menu-button {
            margin-left: auto;
          }
        }
      }
    }
    .main-sidebar {
      .inner-scroll {
        .content-wrapper {
          direction: ltr;
          .navigator {
            padding-left: 0;
            > .item > .item-title .text {
              text-align: left;
            }
          }
        }
      }
    }
    .main-content {
      border-radius: $theme-radius 0 0 $theme-radius;
      margin-left: auto;
      margin-right: 0;
      @include media-breakpoint-down(sm) {
        border-radius: 25px 25px 0 0;
      }

      .profile {
        .dropdown-button {
          .image-user {
            margin-right: 10px;
            margin-left: 0;
          }

          .description {
            padding: 0 30px 0 0;
            i {
              left: auto;
              right: 0;
            }
          }
        }

        .dropdown-list {
          left: 0;
          right: auto;
        }
      }
      .dashboard-page {
        .box-item {
          .title {
            margin-right: 0;
            margin-left: 5px;
          }
        }
        .legend {
          > table {
            left: 29px !important;
            right: auto !important;
          }
        }
      }

      .info-page__box-right {
        .bottom-box {
          .item {
            i {
              margin-right: 7px;
              margin-left: 0;
            }
          }
        }
      }

      .clients-page {
        .section-one__items__box {
          i {
            margin-right: 15px;
            margin-left: 0;
          }
        }
      }

      .grid-view {
        .MuiPaper-root {
          .MuiTableCell-root {
            font-size: 12px;
            text-align: left;
          }
        }
      }

      .MuiFormControl-root {
        &[class*='MTableToolbar-searchField-'] {
          @include media-breakpoint-down(sm) {
            min-width: auto;
            width: 130px;
            padding-left: 4px;
            .MuiInputBase-input {
              font-size: 13px;
            }
            .MuiInputAdornment-root {
              .MuiIconButton-root {
                padding: 4px 0;
              }
            }
          }
        }
      }

      .report-page {
        .box-left {
          .top-row {
            .box-item {
              .title {
                margin-left: 5px;
                margin-right: 0;
              }
              .description {
                margin-left: 5px;
                margin-right: 0;
              }
            }
          }
        }
        .box-right {
          .btn-primary {
            i {
              &.icon-angle-right {
                margin-left: 0 !important;
                margin-right: 0.5rem !important;
                transform: rotate(180deg);
                display: inline-block;
              }
              &.icon-angle-left {
                margin-right: 0 !important;
                margin-left: 0.5rem !important;
                transform: rotate(180deg);
                display: inline-block;
              }
            }
          }
          .button-slide {
            i {
              transform: rotate(180deg);
              margin: 0 6px !important;
            }
          }
          .box-datepicker {
            i {
              margin-left: 0;
              margin-right: 10px;
            }
          }
        }
      }

      .form-check-input {
        margin-left: -1.25rem;
        margin-right: 0;
      }

      #formAwards {
        .icon-plus-2 {
          margin-left: 20px !important;
          margin-right: 5px !important;
        }
      }
    }
    .main-footer {
      padding-left: $width-sidebar;
    }
    @include media-breakpoint-down(sm) {
      .main-footer {
        padding-left: 10px;
      }
    }
  }

  .workingHours {
    .fa-pencil-alt {
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 20px;
      left: auto;
      color: #888;
    }
  }

  .customer-registration {
    @include breakpoint(medium) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @include breakpoint(large) {
      margin-right: -1rem;
      margin-bottom: -4rem;
      margin-left: -1rem;
    }

    .MuiPaper-root {
      padding: 0;
      @include breakpoint(large) {
        padding: 0 1.5rem;
      }
    }
  }


  .first-login-page {
    margin-left: $width-sidebar;

    .section-one {
      border-right: 2px solid $sidebar-background;
    }

    button {
      float: right;
    }
  }
}

.rtl {
  direction: rtl;
  text-align: right;
  [class^='modal---modal-overlay'] {
    [class*='modal---modal-content'] {
      max-width: 600px;
      text-align: right !important;
      font-size: 13px;
      input {
        font-size: 13px;
      }
      textarea {
        font-size: 13px;
      }
      .badge {
        line-height: 1.5;
      }
      .checkbox-group {
        [type='checkbox'] {
          + span {
            font-size: 13px;
          }
        }
      }
      [class^='modal---modal-header'] {
        padding: 16px 24px 16px 56px !important;
      }
      [class^='modal---close'] {
        left: 24px;
        right: auto;
      }
    }
  }
  .messageDate {
    text-align: left;
  }
  .main {
    .main-sidebar {
      .inner-scroll {
        .content-wrapper {
          direction: rtl;
          .navigator {
            padding-right: 0;
            > .item {
              > .item-title .text {
                text-align: right;
              }
              .sidebar-subnav {
                padding-left: 0;
                padding-right: 48px;
                > .item {
                  font-size: 12px;
                  padding: 10px 4px;
                }
              }
            }
          }
        }
      }
    }
    .main-content {
      border-radius: 0 $theme-radius $theme-radius 0;
      margin-left: 0;
      margin-right: auto;
      @include media-breakpoint-down(sm) {
        border-radius: 0 0 25px 25px;
      }

      .profile {
        .dropdown-button {
          .image-user {
            margin-right: 0;
            margin-left: 10px;
          }

          .description {
            i {
              left: 0;
              right: auto;
            }
          }
        }

        .dropdown-list {
          left: auto;
          right: -10px;
        }
      }
      .dashboard-page {
        .box-item {
          .title {
            margin-right: 5px;
            margin-left: 0;
          }
        }
        .legend {
          > table {
            right: 29px !important;
            left: auto !important;
          }
        }
        .select-box {
          margin-right: 0.5rem;
        }
      }

      .info-page__box-right {
        .bottom-box {
          .item {
            i {
              margin-right: 0;
              margin-left: 7px;
            }
          }
        }
      }

      .clients-page {
        .section-one__items__box {
          i {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }

      .grid-view {
        .MuiPaper-root {
          .MuiTableCell-root {
            font-size: 12px;
            text-align: right;
          }
        }
      }

      .MuiFormControl-root {
        &[class*='MTableToolbar-searchField-'] {
          @include media-breakpoint-down(sm) {
            min-width: auto;
            width: 130px;
            padding-left: 4px;
            .MuiInputBase-input {
              font-size: 13px;
            }
            .MuiInputAdornment-root {
              .MuiIconButton-root {
                padding: 4px 0;
              }
            }
          }
        }
      }

      .report-page {
        .box-left {
          .top-row {
            .box-item {
              .title {
                margin-left: 0;
                margin-right: 5px;
              }
              .description {
                margin-left: 0;
                margin-right: 5px;
              }
            }
          }
        }
        .box-right {
          .button-slide {
            i {
              transform: rotate(180deg);
              margin: 0 6px !important;
            }
          }
          .box-datepicker {
            i {
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
      }

      .form-check-input {
        margin-left: 0;
        margin-right: -1.25rem;
      }

      #formAwards {
        .icon-plus-2 {
          margin-left: 5px !important;
          margin-right: 20px !important;
        }
      }

      .MuiTableCell-footer {
        // direction: ltr;
        .MuiTablePagination-toolbar {
          .MuiIcon-root {
            transform: rotate(180deg);
          }
        }
      }
    }
    .main-footer {
      padding-right: $width-sidebar;
    }
    @include media-breakpoint-down(sm) {
      .main-footer {
        padding-right: 10px;
      }
    }
  }

  .profile {
    .dropdown-list {
      @include media-breakpoint-down(sm) {
        right: auto;
        left: 0;
      }
    }
  }

  .react-datepicker-popper {
    @include media-breakpoint-down(sm) {
      left: -200px !important;
      top: 30px !important;
      direction: ltr;
    }
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    @include media-breakpoint-down(sm) {
      margin-left: 140px !important;
    }
  }

  .box-left-wrapper {
    @include media-breakpoint-down(sm) {
      margin-right: 0
    }
  }

  .customer-registration {
    .MuiPaper-root {
      padding: 0;
      @include breakpoint(large) {
        padding: 0 1.5rem;
      }
    }
  }

  .first-login-page {
    margin-right: $width-sidebar;

    .section-one {
      border-left: 2px solid $sidebar-background;
    }

    button {
      float: left;
    }
  }
}

.message-page {
 .title {
   color: $primary;
 }
  .read {
    opacity: 0.6;
  }
  .ellipsis-message,
  .ellipsis-message-title{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableSortLabel-icon {
    display: none;
  }
}

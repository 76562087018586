.clients-page {
  .section-one {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;

    &__items {
      flex-basis: 20%;

      @include media-breakpoint-down(sm) {
        flex-basis: 45%;
        margin-bottom: 5px;
      }

      &__box {
        display: flex;
        align-items: center;
        background-color: white;
        padding: 25px 15px;
        border-radius: 15px;

        i {
          font-size: 40px;
          margin-left: 15px;
        }

        p {
          margin: 0;
        }
        .title {
          font-size: 15px;

          @include media-breakpoint-down(sm) {
            font-size: 11px;
          }
        }

        .description {
          color: #9b9da7;
          font-size: 15px;

          @include media-breakpoint-down(sm) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .section-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .title {
      font-size: 20px;
    }

    input.search {
      border: 0;
      padding: 6px 10px 6px 30px;
      border-radius: 5px;
      box-shadow: 0 1px 6px -4px grey;
    }
    .icon-search {
      color: #9b9da7;
      font-size: 17px;
      position: absolute;
      left: 5px;
      top: 9px;
    }

    .box-filter {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 1px 6px -4px grey;
      margin-right: 15px;
      display: flex;
      align-items: center;
      padding: 4px;

      span {
        padding: 2px 15px;
        cursor: pointer;
        &.active {
          color: white;
          background-color: $primary;
          border-radius: 5px;
        }
      }
    }
  }

  .grid-view {
    .image-profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 10px;
    }

    .name {
      color: $primary;
      font-size: 13px;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }

    .number-mobile {
      margin-top: 10px;
    }

    .location {
      margin-top: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 0 3px;
    }

    .table {
      background-color: white;
      margin: 0;
      text-align: center;

      thead {
        background-color: #f7f8f9;

        th {
          padding: 5px;
        }
      }

      td {
        font-size: 15px;
        padding: 15px 0.75rem;

        .icons {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          margin-top: 10px;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 0;
      }

      [type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
        height: 15px;
        width: 15px;
        background-color: transparent;
        border: 2px solid #26247b;
        top: 5px;
        z-index: 0;
        border-radius: 3px;
      }

      [type='checkbox'].filled-in:checked + span:not(.lever):before {
        top: 3px;
        right: 8px;
        width: 6px;
        height: 11px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #f8f9fa;
        border-bottom: 2px solid #f8f9fa;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
      }

      [type='checkbox'].filled-in:checked + span:not(.lever):after {
        top: 5px;
        height: 15px;
        width: 15px;
        border: 2px solid #26247b;
        background-color: #26247b;
        z-index: 0;
        border-radius: 3px;
      }
    }
  }

  .Tooltip-Wrapper {
    .Tooltip-Tip {
      z-index: 9999;
    }
  }

  .show-panel {
    @include media-breakpoint-down(sm) {
      .input-group {
        font-size: 12px;
      }
    }
  }


  @include media-breakpoint-down(xs) {
    .grid-view .MuiPaper-root .inline-toolbar {
      align-items: baseline !important;
      .MuiToolbar-root {
        display: block !important;
        margin-top: 0.5rem;

        .MuiFormControl-root {
          width: 100% !important;
        }
      }
    }

    .dCheckboxWrapper .form-check-inline {
      margin-right: 0 !important;
      margin-top: 0.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    .grid-view .MuiPaper-root .inline-toolbar {
      align-items: baseline !important;
      .MuiToolbar-root {
        display: block !important;
        margin-top: 0.5rem;

        .MuiFormControl-root {
          width: 100% !important;
        }
      }
    }

    .dCheckboxWrapper .form-check-inline {
      margin-right: 0 !important;
      margin-top: 0.5rem;
    }
  }

  @media only screen and (max-width: 321px) {
    .header-wrapper {
      flex-direction: column;
    }
    .button-wrapper {
      flex-direction: column;
      :nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
.rtl{
  .clients-page {
    .MuiPaper-root {
      .MuiTableCell-root {
        text-align: center !important;
      }
    }
  }

  @media only screen and (max-width: 321px) {
    .button-wrapper {
      :nth-child(1) {
        button {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.ltr {
  @media only screen and (max-width: 321px) {
    .button-wrapper {
      :nth-child(1) {
        button {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.billing-page {
  .tab-content {
    margin-top: 0;
    border-radius: 4px;
    background-color: $white;
    padding: 1rem;
    .show-widget {
      margin: 0;
      border-radius: 0;
    }
  }
  .nav {
    &.nav-tabs {
      padding: 0;
      border: 0 solid #aaa;
      overflow: hidden;
      .nav-item {
        margin-bottom: 0;
        .nav-link {
          border: 0;
          border-radius: 0;
          padding: 15px;
          color: $primary;
          font-weight: bold;
          cursor: pointer;
          border-bottom: 1px solid rgba(170, 170, 170,0.5);
          border-top: 0;
          &.active {
            background: #fff;
            color: #222;
            border: 1px solid rgba(170, 170, 170,0.5);
            border-bottom: 0;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
      }
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
  }
  .billing-table tbody tr td:nth-child(2) {
    width: 80%;
    font-size: 0.9rem;
  }
  .billing-table tbody tr td:first-child {
    font-weight: 700;
    font-size: 0.8rem;
    width: 15%;
  }
  .tiny-text {
    margin-top: 5px;
    font-size: 0.6rem;
  }

  .emptyState {
    border: 1px solid $border;
    border-radius: 4px;
    margin-top: 1rem;
  }
}

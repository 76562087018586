.referral-page {
  .referralInputGroup {
    .dInputWrapper {
      width: 18rem;
    }
    input {
      text-align: left;
    }
  }
}

.hints {
  margin: 2rem 2rem 2rem 1rem;
}

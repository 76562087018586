.customer-registration {
  min-height: 100vh;
  @include breakpoint(medium) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  @include breakpoint(large) {
    margin-right: -1rem;
    margin-bottom: -4rem;
    margin-left: -1rem;
  }

  .MuiPaper-root {
    padding: 1.5rem;
  }

  .box-mobile {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid $border;
    @include breakpoint(large) {
      border-radius: $theme-radius;
      height: calc(100% - 3rem);
    }
    @include breakpoint(ultra) {
      padding: 40px 50px 20px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 25px;
    }
    .prefixNumber {
      margin-right: -30px;
      z-index: 1;
      // color: #356;
    }
    .show-number {
      display: flex;
      flex-direction: column;
      text-align: center;
      border: 1px solid gray;
      border-radius: 10px;
      padding: 10px;

      label {
        color: gray;
        margin: 0;
      }

      input {
        border: 0;
        border-bottom: 1px solid #aaa;
        width: 160px;
        text-align: center;
        margin: 0;
        padding: 10px 10px 10px 30px;
        @include media-breakpoint-down(sm) {
          //font-size: 16px;
        }
      }
    }

    .box-numbers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
      direction: ltr;

      .item {
        flex-basis: 30%;
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 60px;
          height: 60px;
          font-weight: bold;
          background-color: rgb(244, 243, 248);
          border-radius: 50%;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          cursor: pointer;
        }
      }
    }

    .btn-mobile {
      background-color: $primary;
      color: white;
      width: 100%;
      border: 0;
      padding: 15px;
      border-radius: 10px;
      font-size: 18px;
      margin-top: 25px;
      cursor: pointer;
      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    .phone {
      font-size: 1.15rem;
      color: gray;
    }
  }

  &__box-left {
    //padding: 30px 35px 10px;
    //@include media-breakpoint-down(xl) {
    //  padding: 30px 80px 20px 80px;
    //}
    //@include media-breakpoint-down(sm) {
    //  padding: 30px 10px 10px;
    //}

    .title {
      display: flex;
      font-size: 16px;
      font-weight: 500;

      p {
        padding: 0 5px;
      }
    }

    .title-history {
      margin-top: 20px;
      font-size: 19px;
    }

    .show-table {
      .image-profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 10px;
      }

      .name-container {
        text-align: start;
      }

      .name {
        color: $primary;
        font-size: 13px;
        font-weight: bold;
      }
      .name:hover {
        text-decoration: underline;
      }

      .number-mobile {
        font-size: 13px;
        width: 7rem;
        cursor: pointer;
      }

      .points {
        direction: ltr;
      }

      .table {
        background-color: white;
        margin: 0;

        thead {
          background-color: #f7f8f9;

          th {
            padding: 5px;
          }
        }

        td {
          font-size: 15px;
          padding: 15px 0.75rem;

          .icons {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-top: 10px;
          }
        }
      }

      .MuiTableCell-root .icons {
        justify-content: center !important;
      }
      .MuiTableSortLabel-icon {
        display: none;
      }
    }
  }

  .MuiTableCell-root {
    text-align: center !important;
  }

  .user-name {
    width: 7rem;
  }
}

.customer-edit {
  .phone {
    font-size: 1.15rem;
  }
}

.customer-modal {
  .badge {
    padding: 0.5rem 3rem;
    color: $white;
  }
  .activitiesLink:hover {
    background-color: $white!important;
  }

  .birthday-selectbox {
    width: 8rem;
  }
}

.rtl  {
  .customer-edit {
    .button-container {
      float: left;
    }
  }
  .customer-registration {
    .user-name {
      text-align: right;
    }
  }
  .addClient {
    button {
      &:nth-child(3) {
        margin-right: 0.25rem;
      }
    }
    .dRadioWrapper {
      padding-right: 0 !important;
      .horizontal.form-check {
        padding-right: 0 !important;
      }
    }
  }
}

.ltr  {
  .customer-edit {
    .button-container {
      float: right;
    }
  }
  .customer-registration {
    .box-mobile {
      .show-number {
        .dInputWrapper {
          margin-left: -35px;
          input {
            padding-left: 35px;
          }
        }
      }
      .prefixNumber {
        margin-right: unset;
        margin-left: -25px;
      }
    }

    @media(max-width: 320px){
      .box-mobile  {
        .prefixNumber {
          font-size: 0.8rem;
          margin-left: 0;
        }
        input {
          border-bottom: unset;
          font-size: 0.8rem;
        }
      }
    }
  }
  .addClient {
    .dRadioWrapper {
      padding-left: 0 !important;
      .horizontal.form-check {
        padding-left: 0 !important;
      }
    }
  }
}
.addClient {
  .dSelectBoxWrapper {
    min-width: 100px;
  }
  button {
    margin-bottom: 0.5rem;
  }
  @media(max-width: 320px){
    .button-container {
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 0.5rem;
        &:nth-child(3) {
          margin-right: 0.25rem;
          margin-left: 0.25rem;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .show-widget {
      max-width: 75%;
      flex: 0 0 75%;
    }
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .time-wrapper {
      max-width: 100%;
    }
    .times-box {
      flex-direction: column;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 0.5rem;
        &:nth-child(2) {
          margin-right: 0.25rem;
          margin-left: 0.25rem;
        }
      }
    }
  }
}

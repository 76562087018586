.import-page {

  .stepHide {
    display: none;
  }

  .stepShow {
    display: block;
  }

  .step2 {
    table {
      border-bottom: 1px dotted #dedede;
      margin-bottom: 30px !important;
    }
  }

  .progressbar-wrapper {
    background: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 5px;

    .progressbar li {
      list-style-type: none;
      width: 33%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;
    }

    .progressbar li:before {
      width: 60px;
      height: 60px;
      content: "";
      line-height: 60px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 3px auto;
      border-radius: 50%;
      position: relative;
      z-index: 2;
      background-color: #fff;
    }

    .progressbar li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 30px;
      left: 50%;
      z-index: 1;
    }


    .progressbar li.active {
      color: #fff;
      font-weight: bold;
      span {
        color: #7d7d7d;
      }
    }

    .progressbar li.active:before {
      border-color: lighten($primary, 40%);
      background: $primary;
    }

    //.progressbar li.active + li:after {
    //  background-color: lighten($primary, 40%);
    //}

    .progressbar {
      counter-reset: step;
      padding: 0;
    }

    .progressbar li:before {
      content: counter(step);
      counter-increment: step;
    }

    .progressbar li:first-child:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      top: 30px;
      left: 50%;
      z-index: 1;
    }

    .progressbar li:last-child:after {
      content: none;
    }
  }

  a {
    color: $primary !important;
  }
}

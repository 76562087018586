.profile-box {
  border-radius: 4px;
  border: 1px solid $border;
  padding: 0.5rem;
  hr {
    border: unset !important;
    border-bottom: 1px solid $border !important;
    margin: 0.5em 0 2rem !important;
  }
  .logo-uploader {
    margin-top: 1rem;
    width: 150px;
  }
  input {
    margin-bottom: 1.5rem;
  }
  .logo {
    border-radius: 4px;
    border: 2px solid $border;
    margin-top: 0.75rem;
    width: 150px;
    padding: 0.5rem;
    position: relative;

    img{
      width: 100%;
      border-radius: 4px;
    }
    .galleryContainer {
      margin-top: 1rem;

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dotted #ced4da;
        .col-md-4 {
          width: 29.9%;
        }
        .col-md-8 {
          width: 69.9%;
        }
      }
    }
  }

  .delete-logo {
    position: absolute;
    left: 1px;
    top: 0;
    color: var(--danger);
    cursor: pointer;
  }

  #name, #shortName {
    margin-bottom: 0.5rem;
  }
  .infoTxt {
    margin-bottom: 1rem;
    font-size: 0.75rem;
  }
  .galleryRow {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  .column img {
    margin-top: 8px;
    vertical-align: middle;
  }
  @media (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  @media (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }

  .galleryRow img {
    width: 100%;
  }

  .galleryImgBox {
    border: 1px solid $border;
    padding: 5px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 1rem;
  }
  .galleryImgBox .delete-logo {
    visibility: hidden;
  }
  .galleryImgBox:hover .delete-logo {
    visibility: visible;
  }

  .state div[class$="-control"],
  .state div[class*="-control"],
  .state div[class$="-IndicatorsContainer"],
  .state div[class*="-IndicatorsContainer"],
  .state div[class$="-ValueContainer"],
  .state div[class*="-ValueContainer"] {
    height: 42px;
  }

  .social-box {
    .dInputWrapper {
      input {
        padding: 0.2rem 0.3rem;
      }
    }
    hr {
      margin: 0 !important;
      border-bottom: 1px dashed var(--border) !important;
    }
    .socialUrl {
      text-align: left;
    }
  }
  @include media-breakpoint-up(lg) {
    .social-link-wrapper {
      padding: 0;
    }
  }
}
.rtl .profile-box {
  //.social-box {
  //  .icon-bin {
  //    margin-right: 1rem;
  //    @include media-breakpoint-down(lg) {
  //      margin-right: 0;
  //    }
  //  }
  //}
}
.ltr .profile-box {
  //.social-box {
  //  .icon-bin {
  //    margin-left: 1rem;
  //  }
  //}
}
.specialHours {
  .timepicker {
    width: 5rem;
  }
}

.report-page {
  .title-report {
    font-size: 22px;
    margin-top: 30px;
  }
  .box-right {
    background-color: white;
    border-radius: 10px;
    padding: 15px 15px 22px;
    margin-top: 15px;
    border: 1px solid $border;
    @include breakpoint(large) {
      min-height: 14rem;
    }

    .select-box {
      border: 1px solid #c5c5c5;
      padding: 2px 5px;
      border-radius: 7px;

      label {
        margin: 0;
        color: #9b9da7;
      }

      select {
        border: 0;
      }
    }

    .box-filter {
      background-color: white;
      // border-radius   : 5px;
      // box-shadow      : 0px 1px 6px -4px grey;
      margin-right: 15px;
      display: flex;
      align-items: center;
      padding: 2px 4px;
      border: 1px solid #c5c5c5;
      border-radius: 7px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-top: 5px;
      }

      span {
        padding: 2px 15px;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          padding: 2px 6px;
        }
        &.active {
          color: white;
          background-color: $primary;
          border-radius: 5px;
        }
      }
    }

    .box-datepicker {
      background-color: rgb(245, 246, 250);
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 10px;
      flex-basis: 30%;

      &.active {
        border: 2px solid $primary;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        text-align: center;
      }

      i {
        color: #dee1e6;
        font-size: 40px;
        margin-left: 10px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
          margin-bottom: 5px;
          font-size: 30px;
        }
      }

      p {
        margin: 0;
        font-weight: bold;
        font-size: 15px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
          font-size: 10px;
        }
      }
    }

    .button-slide {
      color: white;
      background-color: $secondary;
      border: 0;
      border-radius: 5px;
      margin: 0 3px;
      display: flex;
      padding: 3px 10px;
      align-items: center;
      font-size: 13px;
    }
  }

  .box-left {
    background-color: white;
    border-radius: 10px;
    padding: 15px 15px 7px;
    margin-top: 15px;
    border: 1px solid $border;
    min-height: 14rem;

    .top-row {
      padding-bottom: 15px;
      overflow-x: auto;
      .box-item {
        flex-basis: 30%;
        border-radius: 10px;
        background-color: rgb(245, 246, 250);
        padding: 10px 0 0;
        height: 6rem;
        position: relative;
        border: 1px solid $border;
        min-width: 5rem;
        .title {
          font-size: 14px;
          margin-right: 5px;
          margin-bottom: 2px;
          text-align: center;
        }
        .description {
          font-size: 12px;
          margin-bottom: 0;
          margin-right: 5px;
          text-align: center;
        }
        .sum-box {
          background-color: $primary;
          position: absolute;
          border-radius: 10px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          min-width: 5rem;
          &.sum-1 {
            height: 100px;
          }
          &.sum-2 {
            height: 75px;
          }
          &.sum-3 {
            height: 3rem;
          }

          .box-number {
            color: white;
            border-radius: 15px;
            margin: 0 auto;
            position: absolute;
            left: 10px;
            right: 10px;
            padding: 3px 5px;
            background-color: $primary;
            font-size: 12px;
            bottom: 10px;
            text-align: center;
          }
        }
      }
    }

    .description-bottom {
      color: #c5c5c5;
      font-size: 12px;
    }
  }

  .section-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .title {
      font-size: 20px;
    }

    input.search {
      border: 0;
      padding: 6px 10px 6px 30px;
      border-radius: 5px;
      box-shadow: 0 1px 6px -4px grey;
    }
    .icon-search {
      color: #9b9da7;
      font-size: 17px;
      position: absolute;
      left: 5px;
      top: 9px;
    }
  }

  .show-table {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    border-radius: 15px;

    .name {
      color: $primary;
      font-size: 13px;
      font-weight: bold;
    }

    .number-mobile {
      margin-top: 10px;
    }

    .table {
      background-color: white;
      margin: 0;
      text-align: center;

      thead {
        background-color: #f7f8f9;

        th {
          padding: 5px;
        }
      }

      td {
        font-size: 15px;
        padding: 15px 0.75rem;

        .icons {
          display: flex;
          justify-content: space-around;
          flex-direction: row-reverse;
          margin-top: 10px;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 0;
      }

      .location {
        background-color: rgb(236, 251, 246);
        color: rgb(129, 176, 158);
        width: 60px;
        margin: 10px auto 0;
        font-size: 14px;
      }
    }
  }

  .slideInBox {
    -webkit-animation: slideIn 1s forwards;
    -moz-animation: slideIn 1s forwards;
    animation: slideIn 1s forwards;
  }

  .slideOutBox {
    -webkit-animation: slideOut 1s forwards;
    -moz-animation: slideOut 1s forwards;
    animation: slideOut 1s forwards;
  }

  .report-box {
    align-items: center;
    padding: 0 4px;
    border: 1px solid #c5c5c5;
    border-radius: 7px;

    h6 {
      font-size: 0.8rem;
      margin-top: 4px;
    }
  }

  .grid-view {
    margin-top: -1rem;
  }

  &.activities-wrapper {
    .name-wrapper {
     p {
       color: #d2ab67;
       font-size: 13px;
       font-weight: bold;
       &:hover {
         text-decoration: underline;
       }
     }
    }
  }

  .errorDate {
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }
}

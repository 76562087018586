@import "./Accordion/AccordionStyle";
@import "./Button/ButtonStyle";
@import "./Input/InputStyle";
@import "./Checkbox/CheckboxStyle";
@import "./Radio/RadioStyle";
@import "./Dropdown/DropdownStyle";
@import "./Tab/TabStyle";
@import "./Spinner/SpinnerStyle";
@import "./Select/SelectStyle";
@import "./Modal/ModalStyle";
@import "./Switch/SwitchStyle";
@import "./Badge/BadgeStyle";
@import "./FormatText/FormatText";

body, button, input {
  letter-spacing: inherit;
}

.dButton {
  border-radius: 4px;
}
.dButton.round {
  border-radius: 50px;
}
.dButton.fullWidth {
  width: 100%;
}

button[class^="btn-outline-"], button[class*=" btn-outline-"] {
  border: 1px solid;
}
.textBtn {
  border: none !important;
  box-shadow: none;
}
.dButton.lg{
  padding: .5rem 1rem;
  font-size: 1.015625rem;
  line-height: 1.5;
}
.dButton.md{
  padding: .3rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
}
.dButton.sm {
  padding: 0.20rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-primary {
  color: $on-prime !important;
  background: $primary;
  border: 1px solid darken($primary, 10%);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus{
  color: $on-prime !important;
  background: darken($primary, 10%);
  border: 1px solid $primary;
}

.btn-secondary {
  background: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus{
  background: $secondary;
}

.btn-danger {
  color: $on-prime !important;
  background: $danger;
  border: 1px solid $danger;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus{
  color: $on-prime !important;
  background: $danger;
  border: 1px solid $danger;
}


.btn-outline-primary {
  color: $primary;
  border: 1px solid $primary;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:focus{
  color: #fff !important;
  background-color: $primary;
  border-color: darken($primary, 10%);
}

.btn-outline-secondary {
  color: $secondary;
  border: 1px solid $secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:hover,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus{
  background-color: $secondary;
  border-color: $secondary;
}
.btn-outline-secondary {
  color: $secondary;
  border: 1px solid $secondary;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:hover,
.btn-outline-danger.focus,
.btn-outline-danger:focus{
  background-color: $danger !important;
  border-color: $danger !important;
}
